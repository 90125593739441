import React from "react";

function ServiceGrid({ serviceList, serviceIcons, serviceOverFour }) {
  return (
    <div className="sp-service-grid" data-type={serviceOverFour ? "2x2" : ""}>
      {serviceList.map((service) => {
        return (
          <div className="sp-service-grid-item" key={service.id}>
            <div className="sp-service-grid-card-icon">
              <img src={serviceIcons[service.id - 1]} alt="service-icon"></img>
            </div>
            <p className="sp-service-grid-items-header">{service.title}</p>
            <ul className="sp-service-grid-list">
              {service.items.map((item, i) => {
                return (
                  <li className="sp-service-grid-list-item" key={i}>
                    {item}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default ServiceGrid;
