import React from "react";

function GoogleMapsFrame({ mapIndex }) {
  const locationFrames = [
    <iframe
      className="service-location-map"
      title="Epikur Falkenberg"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      id="gmap_canvas"
      src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=de&amp;q=Bahnhofstra%C3%9Fe%204A%20Falkenberg%20(Elster)+(Epikur)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>,

    <iframe
      className="service-location-map"
      title="Epikur Herzberg"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      id="gmap_canvas"
      src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=de&amp;q=Torgauer%20Str.%2068-70%20Herzberg+(Epikur)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>,

    <iframe
      className="service-location-map"
      title="Epikur Elsterwerda"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      id="gmap_canvas"
      src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=de&amp;q=Hauptstra%C3%9Fe%2036-38%20Elsterwerda+(Epikur)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>,

    <iframe
      className="service-location-map"
      title="Epikur Finsterwalde"
      frameBorder="0"
      referrerPolicy="no-referrer-when-downgrade"
      id="gmap_canvas"
      src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=de&amp;q=Berliner%20Str.%2020%20Finsterwalde+(Epikur)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>,
  ];

  return (
    <div className="service-location-map-wrapper">
      {locationFrames[mapIndex]}
    </div>
  );
}

export default GoogleMapsFrame;
