import React from "react";
import { HashLink } from "react-router-hash-link";

function Footer() {
  const YEAR = new Date().getFullYear();

  return (
    <footer>
      <div className="padding-block-500 padding-inline">
        <div className="primary-footer">
          <div className="footer-copyright">
            {"© " + YEAR + " Epikur - Zentrum für Gesundheit"}
          </div>
          <div className="primary-footer-nav">
            <nav className="footer-nav">
              <HashLink to="/datenschutz">Datenschutz</HashLink>
              <HashLink to="/impressum">Impressum</HashLink>
              <HashLink to="/jobs">Jobs</HashLink>
              <HashLink to="/kontakt">Kontakt</HashLink>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
