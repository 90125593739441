import React, { useEffect, useState } from "react";
import ContactForm from "../components/Forms/ContactForm";
import { HashLink } from "react-router-hash-link";

function Contact() {
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  const ContactFormComponent = () => {
    if (showConfirmation) {
      return (
        <div className="form-submit-message">
          <p>Herzlichen Dank für ihre Kontaktanfrage.</p>
          <p>
            Hiermit bestätigen wir die Ankunft dieser über das Kontaktformular.
            Wir werden Sie so schnell wie möglich über die von Ihnen angegebene
            E-Mail kontaktieren.
          </p>
          <HashLink to="/" className="btn">
            Zurück zur Startseite
          </HashLink>
        </div>
      );
    } else {
      return <ContactForm AfterSubmitAction={setShowConfirmation} />;
    }
  };

  return (
    <div className="contact-wrapper" id="contact">
      <div className="container">
        <h1 className="contact-header text-center">Kontaktformular</h1>
        <ContactFormComponent />
      </div>
    </div>
  );
}

export default Contact;
