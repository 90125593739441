import React from "react";
import ServicePage from "./ServicePage";
import mediData from "../../data/medizin.json";
import iconResearch from "../../assets/img/Untersuchung.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconBetreuung from "../../assets/img/health-checklist.webp";
import heroImg from "../../assets/img/AdobeStock_242094716_Preview.jpeg";

function Medizin() {
  const mediServices = mediData.services;
  const mediLocationData = mediData.locationData;
  const mediIcons = [iconBetreuung, iconHealthCare, iconResearch];
  const mediHeader = mediData.header;
  const mediAbout = mediData.about;

  return (
    <ServicePage
      header={mediHeader}
      heroImg={heroImg}
      aboutText={mediAbout}
      serviceList={mediServices}
      serviceIcons={mediIcons}
      locationList={mediLocationData}
      showLocationComponent={false}
      serviceOverFour={false}></ServicePage>
  );
}

export default Medizin;
