import React from "react";
import ServicePage from "./ServicePage";
import physioData from "../../data/physioData.json";
import iconSelfcare from "../../assets/img/heart-person.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconBetreuung from "../../assets/img/health-checklist.webp";
import heroImg from "../../assets/img/AdobeStock_160757660.jpeg";

function Physiotherapie() {
  const physioServices = physioData.services;
  const physioLocationData = physioData.locationData;
  const physioIcons = [iconHealthCare, iconBetreuung, iconSelfcare];
  const physioHeader = physioData.header;
  const physioAbout = physioData.about;

  return (
    <ServicePage
      header={physioHeader}
      heroImg={heroImg}
      aboutText={physioAbout}
      serviceList={physioServices}
      serviceIcons={physioIcons}
      locationList={physioLocationData}
      showLocationComponent={true}
      serviceOverFour={false}></ServicePage>
  );
}

export default Physiotherapie;
