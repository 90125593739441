import React from "react";
import { HashLink } from "react-router-hash-link";
import ServiceGrid from "./components/ServiceGrid";
import ServiceLocationPicker from "./components/ServiceLocationPicker";
import navIcon1 from "../../assets/img/calender_icon.webp";
import navIcon2 from "../../assets/img/service_icon.webp";
import navIcon3 from "../../assets/img/pin_icon.webp";

function ServicePage({
  header,
  heroImg,
  aboutText,
  serviceList,
  serviceIcons,
  locationList,
  showLocationComponent,
  serviceOverFour,
}) {
  return (
    <div className="section-container">
      <div className="sp-hero-wrapper">
        <div className="sp-hero-content container">
          <h2 className="sp-hero-sub-header">Epikur</h2>
          <h1 className="seciton-hero-header">{header}</h1>
        </div>
        <div className="sp-hero-img-wrapper">
          <div className="sp-hero-img-blender"></div>
          <img
            className="sp-hero-img"
            src={heroImg}
            alt="Bild Fachrichtung"></img>
        </div>
        <div className="sp-hero-nav">
          <HashLink className="sp-hero-nav-item" to="#termin-vereinbaren">
            <img
              className="sp-hero-nav-item-img"
              src={navIcon1}
              alt="Leistungen Icon"></img>
            <div className="sp-hero-nav-item-title">Terminvereinbarung</div>
          </HashLink>
          <HashLink className="sp-hero-nav-item" to="#leistungen">
            <img
              className="sp-hero-nav-item-img"
              src={navIcon2}
              alt="Leistungen Icon"></img>
            <div className="sp-hero-nav-item-title">Leistungen</div>
          </HashLink>
          <HashLink className="sp-hero-nav-item" to="#kontakt-und-anfahrt">
            <img
              className="sp-hero-nav-item-img"
              src={navIcon3}
              alt="Leistungen Icon"></img>
            <div className="sp-hero-nav-item-title">Kontakt & Anfahrt</div>
          </HashLink>
        </div>
      </div>

      <div className="sp-about-wrapper ">
        <h3 className="sp-about-header">{header}</h3>
        <div className="sp-about-text-wrapper">
          {aboutText.map((element, i) => {
            return (
              <p className="sp-about-text" key={i}>
                {element}
              </p>
            );
          })}
        </div>
      </div>

      <div className="sp-appointment-wrapper" id="termin-vereinbaren">
        <div className="appointment-wrapper">
          <div className="container">
            <div className="appointment-content">
              <h4 className="appointment-title">
                Jetzt einen Termin vereinbaren.
              </h4>
              <HashLink className="appointment-btn" to={"/kontakt"}>
                Termin vereinbaren
              </HashLink>
            </div>
          </div>
        </div>
      </div>

      <div
        className="sp-service-wrapper container"
        id="leistungen"
        data-type={showLocationComponent ? "" : "noLocationComponent"}>
        <h3 className="sp-service-header">Wir behandeln bei</h3>
        <ServiceGrid
          serviceList={serviceList}
          serviceIcons={serviceIcons}
          serviceOverFour={serviceOverFour}
        />
        <p className="sp-service-info">
          Auf Verordnung vom Arzt kann die Behandlung auch im Hausbesuch oder
          Heim erfolgen.
        </p>
      </div>

      {showLocationComponent ? (
        <div className="sp-contact-wrapper container" id="kontakt-und-anfahrt">
          <h3 className="sp-contact-header">Vor Ort für Sie da</h3>
          <ServiceLocationPicker locationData={locationList} />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ServicePage;
