import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";
import chevronInCircle from "../assets/img/cheron_in_circle.svg";
import logo from "../assets/img/logo.webp";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isMobile = useMediaQuery({ query: "(max-width: 66em)" });

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
    setMobileBurgerState(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
    setMobileBurgerState(false);
  };

  const setMobileBurgerState = (setOpen) => {
    let mobileMenu = document.querySelector(".nav-mobile");
    setBurgerMenuState(mobileMenu, setOpen);
  };

  const setBurgerMenuState = (burgerMenu, setOpen) => {
    const burgerMenuTop = burgerMenu.children[0];
    const burgerMenuCenter = burgerMenu.children[1];
    const burgerMenuBottom = burgerMenu.children[2];

    if (setOpen) {
      burgerMenuTop.setAttribute("data-type", "toX");
      burgerMenuCenter.setAttribute("data-type", "toX");
      burgerMenuBottom.setAttribute("data-type", "toX");
    } else {
      burgerMenuTop.setAttribute("data-type", "reverse");
      burgerMenuCenter.setAttribute("data-type", "reverse");
      burgerMenuBottom.setAttribute("data-type", "reverse");
    }
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-menu">
          <div className="nav-mobile-links">
            <HashLink
              to="/#top"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Start</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/ergotherapie"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Ergotherapie</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/physiotherapie"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Physiotherapie</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/betriebsmedizin-arbeitsmedizin"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Betriebsmedizin</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/ernährungsberatung"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Ernährungsberatung</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/kontakt"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Kontakt</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>

            <HashLink
              to="/jobs"
              className="nav-link-wrapper"
              onClick={() => onNavLinkClicked()}>
              <div className="nav-link">Jobs</div>
              <img
                className="nav-link-img"
                src={chevronInCircle}
                alt="Chevron"></img>
            </HashLink>

            <div className="nav-divider"></div>
          </div>
        </div>
      );
    }
    return;
  };

  const DesktopMenu = () => {
    if (!isMobile) {
      return (
        <>
          <HashLink className="nav-link" to="/#top">
            Start
          </HashLink>

          <HashLink className="nav-link" to="/ergotherapie">
            Ergotherapie
          </HashLink>

          <HashLink className="nav-link" to="/physiotherapie">
            Physiotherapie
          </HashLink>

          <HashLink className="nav-link" to="/betriebsmedizin-arbeitsmedizin">
            Betriebsmedizin
          </HashLink>

          <HashLink className="nav-link" to="/ernährungsberatung">
            Ernährungsberatung
          </HashLink>

          <HashLink className="nav-link nav-link-highlight" to="/kontakt">
            Kontakt
          </HashLink>
        </>
      );
    } else {
      return;
    }
  };

  return (
    <div className="nav-container">
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="Epikur GmbH Logo"></img>
        </HashLink>
        <div className="nav-links">
          {isMobile ? (
            <>
              <div className="nav-mobile" onClick={(e) => openMobileMenu()}>
                <div className="nav-burger-icon-upper nav-burger-icon"></div>
                <div className="nav-burger-icon-center nav-burger-icon"></div>
                <div className="nav-burger-icon-lower nav-burger-icon"></div>
              </div>
            </>
          ) : (
            <DesktopMenu />
          )}
        </div>
      </div>
      <MobileMenu />
    </div>
  );
}

export default Navbar;
