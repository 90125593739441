import React from "react";
import ServicePage from "./ServicePage";
import nutriData from "../../data/nutriData.json";
import iconSelfcare from "../../assets/img/heart-person.webp";
import iconHealthCare from "../../assets/img/health-care.webp";
import iconNote from "../../assets/img/note-icon.webp";
import kochKursNote from "../../assets/img/psychologie_icon.webp";
import heroImg from "../../assets/img/AdobeStock_220140088_Preview.jpeg";

function Ernaehrungsberatung() {
  const nutriServices = nutriData.services;
  const nutriLocationData = nutriData.locationData;
  const nutriIcons = [iconHealthCare, iconSelfcare, iconNote, kochKursNote];
  const nutriHeader = nutriData.header;
  const nutriAbout = nutriData.about;

  return (
    <ServicePage
      header={nutriHeader}
      heroImg={heroImg}
      aboutText={nutriAbout}
      serviceList={nutriServices}
      serviceIcons={nutriIcons}
      locationList={nutriLocationData}
      showLocationComponent={true}
      serviceOverFour={true}></ServicePage>
  );
}

export default Ernaehrungsberatung;
