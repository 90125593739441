import React from "react";
import ServicePage from "./ServicePage";
import ergoData from "../../data/ergoData.json";
import icon1 from "../../assets/img/neurologie_icon.webp";
import icon2 from "../../assets/img/psychologie_icon.webp";
import icon3 from "../../assets/img/orthopaedie_icon.webp";
import heroImg from "../../assets/img/AdobeStock_265993827.jpeg";

function Ergotherapie() {
  const ergoServices = ergoData.services;
  const ergoLocationData = ergoData.locationData;
  const ergoIcons = [icon1, icon3, icon2];
  const ergoHeader = ergoData.header;
  const ergoAbout = ergoData.about;

  return (
    <ServicePage
      header={ergoHeader}
      heroImg={heroImg}
      aboutText={ergoAbout}
      serviceList={ergoServices}
      serviceIcons={ergoIcons}
      locationList={ergoLocationData}
      showLocationComponent={true}
      serviceOverFour={false}></ServicePage>
  );
}

export default Ergotherapie;
