import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./assets/styles/App.css";
import Main from "./Main";
import Contact from "./pages/Contact";
import Jobs from "./pages/Jobs";
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Ergotherapie from "./components/ServicePages/Ergotherapie";
import Physiotherapie from "./components/ServicePages/Physiotherapie";
import Ernaehrungsberatung from "./components/ServicePages/Ernaehrungsberatung";
import Medizin from "./components/ServicePages/Medizin";
import Appointment from "./pages/Appointment";

function RoutesList() {
  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/jobs" element={<Jobs />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
      <Route path="/impressum" element={<Impressum />} />
      <Route path="/ergotherapie" element={<Ergotherapie />} />
      <Route path="/physiotherapie" element={<Physiotherapie />} />
      <Route path="/ernährungsberatung" element={<Ernaehrungsberatung />} />
      <Route path="/betriebsmedizin-arbeitsmedizin" element={<Medizin />} />
      <Route path="/termin-vereinbaren" element={<Appointment />} />
    </Routes>
  );
}

export default RoutesList;
