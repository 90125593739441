import React from "react";

export class ScrollAgent extends React.Component {
  componentDidMount() {
    const navbar = window.document.getElementsByClassName("nav")[0];

    window.onscroll = function () {
      scrollFunction();

      if (window.location.pathname === "/") {
        var serviceGrid =
          window.document.getElementsByClassName("service-grid")[0];
        onShowServiceGrid(serviceGrid);
      }
    };

    function scrollFunction() {
      navBarEffect();
    }

    function navBarEffect() {
      if (
        document.body.scrollTop > 75 ||
        document.documentElement.scrollTop > 75
      ) {
        navbar.setAttribute("data-type", "minimize");
      } else {
        navbar.removeAttribute("data-type");
      }
    }

    function onShowServiceGrid(serviceGrid) {
      var serviceGridItems = serviceGrid.childNodes;

      if (
        document.body.scrollTop > 400 ||
        document.documentElement.scrollTop > 400
      ) {
        serviceGridItems.forEach((item) => {
          item.setAttribute("data-type", "test");
        });
      }
    }
  }

  render() {
    return <></>;
  }
}

export default ScrollAgent;
