import React from "react";
import Home from "./pages/Home";

import ImageGallery from "./components/AdvancedImageGallery";

function Main() {
  return (
    <>
      <Home />
      <ImageGallery />
    </>
  );
}

export default Main;
