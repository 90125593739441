import React, { useEffect } from "react";
import { HashLink } from "react-router-hash-link";
import hrImage from "../assets/img/AdobeStock_175855231.webp";
import cardImg1 from "../assets/img/AdobeStock_160757660.webp";
import cardImg2 from "../assets/img/AdobeStock_265993827.webp";
import cardImg3 from "../assets/img/AdobeStock_199949296.webp";
import cardImg4 from "../assets/img/AdobeStock_220140088.webp";
import aboutImg from "../assets/img/AdobeStock_126566030.webp";

function Home() {
  return (
    <>
      <section id="home">
        <div className="hr-wrapper">
          <div className="hr-content-wrapper">
            <h1 className="hr-primary-heading fw-bold">EPIKUR</h1>
            <h1 className="hr-secondary-heading fw-bold">
              Zentrum für Gesundheit
            </h1>
            <h3 className="hr-tertiary-heading">
              Die Anlaufstelle für ihr Wohlbefinden
            </h3>
            <HashLink className="hr-appointment-btn" to={"/kontakt"}>
              Termin vereinbaren
            </HashLink>
          </div>
          <HashLink className="hr-job" to={"/jobs"}>
            <p className="hr-job-text fw-bold">
              ENGARGIERTE MITARBEITER GESUCHT!
            </p>
            <p className="hr-job-text">JETZT BEWERBEN</p>
          </HashLink>
          <div className="hr-img-wrapper">
            <img className="hr-img" src={hrImage} alt="heroImg"></img>
            <div className="hr-img-blender"></div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container">
          <div className="service-header-content">
            <h2 className="service-heading">Unsere Leistungen</h2>
            <p className="service-text">
              Unser Gesundheitszentrum vereint Ergotherapie, Physiotherapie,
              Betriebsmedizin und Ernährungsberatung unter einem Dach. Mit einem
              interdisziplinären Ansatz fördern wir Ihre Gesundheit,
              Beweglichkeit und Leistungsfähigkeit – individuell abgestimmt auf
              Ihre Bedürfnisse. Vertrauen Sie auf unsere Expertise für Ihr
              Wohlbefinden und Ihre Gesundheit!
            </p>
          </div>
        </div>

        <div className="service-grid-wrapper">
          <div className="container">
            <div className="service-grid">
              <HashLink className="service-grid-item" to="/ergotherapie">
                <img
                  className="service-grid-item-img"
                  src={cardImg2}
                  alt="card-img"></img>
                <div className="service-grid-item-content">
                  <div className="service-grid-item-content-header">
                    Ergotherapie
                  </div>
                  <HashLink
                    to="/ergotherapie"
                    className="service-grid-item-content-link">
                    <span>{"mehr erfahren"}</span>
                  </HashLink>
                </div>
              </HashLink>

              <HashLink className="service-grid-item" to="/physiotherapie">
                <img
                  className="service-grid-item-img"
                  src={cardImg1}
                  alt="card-img"></img>
                <div className="service-grid-item-content">
                  <div className="service-grid-item-content-header">
                    Physiotherapie
                  </div>
                  <HashLink
                    to="/physiotherapie"
                    className="service-grid-item-content-link">
                    <span>{"mehr erfahren"}</span>
                  </HashLink>
                </div>
              </HashLink>

              <HashLink
                className="service-grid-item"
                to="/betriebsmedizin-arbeitsmedizin">
                <img
                  className="service-grid-item-img"
                  src={cardImg3}
                  alt="card-img"></img>
                <div className="service-grid-item-content">
                  <div className="service-grid-item-content-header">
                    Betriebsmedizin/Arbeitsmedizin
                  </div>
                  <HashLink
                    to="/betriebsmedizin-arbeitsmedizin"
                    className="service-grid-item-content-link">
                    <span>{"mehr erfahren"}</span>
                  </HashLink>
                </div>
              </HashLink>

              <HashLink className="service-grid-item" to="/ernährungsberatung">
                <img
                  className="service-grid-item-img"
                  src={cardImg4}
                  alt="card-img"></img>
                <div className="service-grid-item-content">
                  <div className="service-grid-item-content-header">
                    Ernährungsberatung
                  </div>
                  <HashLink
                    to="/ernährungsberatung"
                    className="service-grid-item-content-link">
                    <span>{"mehr erfahren"}</span>
                  </HashLink>
                </div>
              </HashLink>
            </div>
          </div>
        </div>
      </section>

      <section id="about">
        <div className="appointment-wrapper">
          <div className="container">
            <div className="appointment-content">
              <h4 className="appointment-title">
                Jetzt einen Termin vereinbaren.
              </h4>
              <HashLink className="appointment-btn" to={"/kontakt"}>
                Termin vereinbaren
              </HashLink>
            </div>
          </div>
        </div>

        <div className="container" data-type="mobile">
          <div className="about-wrapper">
            <div className="about-img-wrapper">
              <img
                className="about-img"
                src={aboutImg}
                alt="Über Uns Bild"></img>
            </div>
            <div className="about-content">
              <p className="about-content-text">
                Epikur GmbH, Zentrum für Gesundheit ist eine moderne
                Einrichtung, die ganzheitliche Gesundheitslösungen für Menschen
                jeden Alters bietet. Mit einem vielfältigen Leistungsspektrum,
                darunter Ergotherapie, Physiotherapie, Betriebs- und
                Arbeitsmedizin sowie Ernährungsberatung, stehen wir für
                präventive und rehabilitative Betreuung.
              </p>
              <br />
              <p className="about-content-text">
                Unsere qualifizierten Fachkräfte entwickeln individuell
                abgestimmte Behandlungspläne, um ihre Gesundheit zu fördern und
                Lebensqualität zu verbessern. Ob zur Unterstützung der
                Mobilität, zur Förderung beruflicher Gesundheit oder zur
                Optimierung der Ernährung setzen wir auf auf innovative Ansätze
                und persönliche Betreuung. Als zuverlässiger Partner bieten wir
                umfassende Lösungen für ein gesundes und ausgeglichenes Leben.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
