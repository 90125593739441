import React from "react";
import { useState, useEffect } from "react";
import GoogleMapsFrame from "./GoogleMapsFrame";
import locationArray from "../../../data/locations.json";
import { HashLink } from "react-router-hash-link";

function ServiceLocationPicker({ locationData }) {
  const [activeLocation, setActiveLocation] = useState(0);
  const [locationsKeyList, setLocationsKeyList] = useState([]);
  const [locationComponent, setLocationComponent] = useState(<></>);

  useEffect(() => {
    var locArray = [];
    var index = 0;

    locationData.forEach((element) => {
      locArray.push({
        id: index,
        location: locationArray[element.locationId].location,
        active: false,
      });
      index++;
    });
    locArray[activeLocation].active = true;
    setLocationsKeyList(locArray);
  }, [activeLocation, locationData]);

  useEffect(() => {
    var LOCATION_DATA = locationArray[activeLocation];
    var locationSpecificData = locationData[activeLocation];
    var timeId = 0;

    setLocationComponent(
      <div>
        <div className="service-location-info">
          <div className="service-location-info-left">
            <div className="service-location-info-wrapper">
              <p className="service-location-info-header">Adresse:</p>
              <p className="service-location-info-text">
                {LOCATION_DATA.address.street}
              </p>
              <p className="service-location-info-text">
                {LOCATION_DATA.address.pincode +
                  " " +
                  LOCATION_DATA.address.city}
              </p>
              <HashLink
                className="service-location-info-link"
                data-type="maps"
                to={LOCATION_DATA.link}
                target="_blank"
                rel="noopener noreferrer">
                {"Jetzt Route planen >"}
              </HashLink>
            </div>
            <div className="service-location-info-wrapper">
              <p className="service-location-info-header">Ansprechpartner:</p>
              <p className="service-location-info-text">
                {locationSpecificData.contact.person}
              </p>
              {locationSpecificData.contact.titles ? (
                <>
                  {locationSpecificData.contact.titles.map((element) => {
                    return <p>{element}</p>;
                  })}
                </>
              ) : (
                <></>
              )}
              {locationSpecificData.contact.mob ? (
                <div className="service-location-info-text-wrapper">
                  <p className="service-location-info-text">Mobil: </p>
                  <HashLink
                    className=" service-location-info-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={"tel:" + locationSpecificData.contact.mob}>
                    {locationSpecificData.contact.mob}
                  </HashLink>
                </div>
              ) : (
                <></>
              )}

              <div className="service-location-info-text-wrapper">
                <p className="service-location-info-text">Tel: </p>
                <HashLink
                  className=" service-location-info-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"tel:" + locationSpecificData.contact.tel}>
                  {locationSpecificData.contact.tel}
                </HashLink>
              </div>

              <div className="service-location-info-text-wrapper">
                <p className="service-location-info-text">Email: </p>
                <HashLink
                  className=" service-location-info-link"
                  target="_blank"
                  rel="noopener noreferrer"
                  to={"mailto:" + locationSpecificData.contact.mail}>
                  {locationSpecificData.contact.mail}
                </HashLink>
              </div>
            </div>
          </div>
          <div className="service-location-info-right">
            <div className="service-location-info-wrapper">
              {locationSpecificData.contact.openingTimes.length > 0 ? (
                <>
                  <p className="service-location-info-header">
                    Öffnungszeiten:
                  </p>
                  {locationSpecificData.contact.openingTimes.map((times) => {
                    timeId++;
                    return (
                      <p className="service-location-info-text" key={timeId}>
                        {times}
                      </p>
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <GoogleMapsFrame mapIndex={LOCATION_DATA.googleMaps} />
      </div>
    );
  }, [activeLocation, locationsKeyList, locationData]);

  const onLocationClick = (index) => {
    setActiveLocation(index);
  };

  const RenderLocationPicker = () => {
    return locationsKeyList.map((element, i) => {
      if (element.active) {
        return (
          <div
            className="service-location-picker-item"
            key={i}
            data-type="active"
            onClick={() => onLocationClick(i)}>
            {element.location}
          </div>
        );
      } else {
        return (
          <div
            className="service-location-picker-item"
            key={i}
            onClick={() => onLocationClick(i)}>
            {element.location}
          </div>
        );
      }
    });
  };

  return (
    <>
      <div className="service-location-picker">{RenderLocationPicker()}</div>
      <div className="service-location">{locationComponent}</div>
    </>
  );
}

export default ServiceLocationPicker;
